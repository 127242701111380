<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col md="6" v-for="(feed, index) in data" :key="index" class="mb-4">
        <ActivityFeedCard
          :feedDetail="feed"
          :themeColor="themeColor"
          @click="onClick(feed.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ActivityFeedCard from "@/components/Teams/Cards/ActivityFeedCard.vue";
export default {
  props: ["data", "discussionTopicId"],
  components: {
    ActivityFeedCard
  },
  computed: {
    themeColor() {
      return this.$route.query.themeColor;
    }
  },
  watch: {
    data(val) {
      if (val.length > 2) {
        this.$parent.$emit("hasData");
      }
    }
  },
  methods: {
    onClick(id) {
      this.$router.push({
        name: "AcitvityFeedDetail",
        params: {
          activityId: this.discussionTopicId,
          feedId: id
        },
        query: {
          themeColor: this.themeColor
        }
      });
    }
  }
};
</script>
