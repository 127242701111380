<template>
  <div class="td-card-wrappper">
    <b-card @click="onCardClick">
      <div class="d-flex">
        <div class="hdr-img" :style="`background: ${themeColor}`">
          <img
            src="@/assets/images/icons/teams-message.svg"
            alt="teams"
            width="auto"
            height="auto"
          />
        </div>
        <b-card-text class="card-title__team" :title="feedDetail.title">{{
          feedDetail.title
        }}</b-card-text>
      </div>
      <b-card-text class="card-body__team">{{ feedDetail.body }}</b-card-text>
      <div class="card-footer__team">
        <!-- <b-badge pill class="td-card-badge" variant="light"
          ><img
            src="@/assets/images/icons/reply.svg"
            alt="teams"
            width="auto"
            height="auto"
          />
          {{
            feedDetail.response_count === 1
              ? `${feedDetail.response_count} reply`
              : `${feedDetail.response_count} replies`
          }}
        </b-badge> -->
        <b-badge pill class="td-card-badge" variant="light"
          ><img
            src="@/assets/images/icons/history.svg"
            alt="teams"
            width="auto"
            height="auto"
          />
          {{
            $t("discussions.category.last_post_x_ago", {
              s: lastActivityAt
            })
          }}
        </b-badge>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    feedDetail: Object,
    themeColor: {
      type: String,
      default: "#e1ddff"
    }
  },
  computed: {
    lastActivityAt() {
      return this.$options.filters.humanize(this.feedDetail.last_activity_at);
    }
  },
  methods: {
    onCardClick() {
      this.$emit("click");
    }
  }
};
</script>
<style lang="scss" scoped>
.td-card-wrappper {
  .card {
    background: #ffffff;
    border: 0;
    border-radius: 8px;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: box-shadow 0.25s;

    &:hover {
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    }

    .card-body {
      padding: 15px;
    }

    .hdr-img {
      min-width: 40px;
      height: 40px;
      background: #e1ddff;
      border-radius: 8px;
      text-align: center;
      padding-top: 6px;
    }
    .card-title__team {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #191c1d;
      padding: 7px 0 10px 15px;

      @include text-ellipsis;
    }
    .card-body__team {
      font-size: 14px;
      line-height: 20px;
      color: #191c1d;
      padding: 0px 0px 10px 57px;
      min-height: 60px;
      @include line-clamp(3);
    }
    .card-footer__team {
      padding-left: 57px;
      display: flex;
      justify-content: flex-start;
      .td-card-badge {
        padding: 5px 10px;
        background: #fff;
        color: #44464e;
        border: 1px solid #a1a2a6;
      }
    }
  }
}

[dir="rtl"] {
  .td-card-wrappper {
    .card {
      .card-title__team {
        padding: 7px 15px 10px 0;
      }
      .card-body__team {
        text-align: right;
        padding: 0px 57px 10px 0px;
      }
      .card-footer__team {
        padding: 0 57px 0 0;
      }
    }
  }
}
</style>
